



























import Vue from "vue";

import { WorldInfo } from "#mws/interfaces";
import { t } from '#root/lib/utils';
import { LocaleKeys } from "#mws/data";

export default Vue.extend({
  name: "PauseMenu",
  created() {
    document.addEventListener("keyup", this.onKeyUp);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyUp);
  },
  data: () => ({
    shown: false,
    returnToMenuFlag: false,
    lk: LocaleKeys
  }),
  props: {
    worldInfo: {
      type: Object as () => WorldInfo,
    },
  },
  methods: {
    onKeyUp(keyEvent: KeyboardEvent): void {
      if (keyEvent.key === "Escape") {
        this.close();
      }
    },
    open(): void {
      this.shown = true;
    },
    close(): void {
      this.shown = false;
    },
    returnToMenu(): void {
      this.returnToMenuFlag = true;
      this.shown = false;
    },
    t: t
  },
  watch: {
    shown(value: boolean) {
      if (value === false) {
        if (this.returnToMenuFlag) {
          this.returnToMenuFlag = false;
          this.$emit("returnToMenu");
        } else {
          this.$emit("closed");
        }
      }
    },
  },
});
